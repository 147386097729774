"use client"
import React, {useEffect, useState}                                  from 'react'
import Layout                                                        from '../components/shared/layout'
import {Card, CardContent, Container, Grid, Link, Theme, Typography} from '@material-ui/core'
import {graphql}                                                     from 'gatsby'
import {NewsItem}                                                    from '../../entities/barrister'
import theme                                                         from '../theme'
import {getNodesFromEdges}                                           from '../helpers/helpers'
import {makeStyles}                                                  from '@material-ui/core/styles'
import SEO                                                           from '../components/shared/seo'
import moment                                                        from 'moment'
import SanityImage                                                   from 'gatsby-plugin-sanity-image'


export const query = graphql`
    query {
        allSanityBlog(sort: {order: DESC, fields: publishedAt}) {
            edges {
                node {
                    title
                    _rawBody(resolveReferences: {maxDepth: 5})
                    publishedAt
                    mainImage {
                        ...ImageData
                    }
                    slug {
                        current
                    }
                }
            }
        }
    }
`


const useStyles = makeStyles((theme: Theme) => ({
    article: {},
    articleTitle: {
        '&:hover': {
            textDecoration: 'none'
        }
    },
    headerContainer: {},
    byline: {},
    dateLine: {},
    bodyWrapper: {backgroundColor: '#FFF'},
    bodyContainer: {},
    blogListItem: {},
    articleListContainer: {
        placeItems: 'center'
    },
    newsCard: {
        width: '100%'
    },
    cardContent: {

        '&:hover': {
            background: '#F5F5F5'
        }
    }
}))

interface NewsProps {
    data: { allSanityBlog: { edges: { node: NewsItem }[] } }
}

const NewsPage = (props: NewsProps) => {
    // console.log('News page props', props)
    const {data} = props
    const classes = useStyles(theme)

    const [blogList, setBlogList] = useState<NewsItem[] | undefined>()

    useEffect(() => {
        const blogs = getNodesFromEdges(data.allSanityBlog.edges)
        setBlogList(blogs)
    }, [])

    const renderNewsList = (news: NewsItem[]) => (
        <Grid container spacing={4}>
            {
                news.map((newsItem: NewsItem, idx: number) => (
                    <Grid key={idx} container justify={'center'} alignItems={'stretch'} item xs={12} sm={6} md={4}>
                        <Card className={classes.newsCard}>
                            <Link href={`/news/${newsItem.slug.current}`} component={'a'}>
                                <SanityImage
                                    style={{objectFit: 'cover', width: '100%', height: 300}}
                                    {...newsItem.mainImage}
                                    height={800}/>
                            </Link>
                            <CardContent className={classes.cardContent}>
                                <Typography
                                    gutterBottom
                                    variant={'h6'}
                                    component={'h3'}
                                    className={classes.blogListItem}
                                    key={idx}
                                >
                                    <Link className={classes.articleTitle} href={`/news/${newsItem.slug.current}`}
                                          component={'a'}>
                                        {newsItem.title} &rarr;
                                    </Link>
                                </Typography>
                                {
                                    newsItem.publishedAt && (
                                                             <Typography variant={'caption'} component={'p'}>
                                                                 {moment(newsItem.publishedAt).format('D MMMM YYYY')}
                                                             </Typography>
                                                         )
                                }
                            </CardContent>

                        </Card>
                    </Grid>
                ))
            }
        </Grid>
    )

    return (
        <Layout helmetData={{
            pageTitle: 'News',
            metaDescription: 'The latest news from Level 22 Chambers, a leading commercial and administrative set based in Sydney, Australia.',
            slug: 'news'
        }} backgroundImage={''}>
            <SEO title={'News'} description={'News page and blog for Level 22 Chambers'}/>
            <Container maxWidth={'xl'} className={classes.bodyWrapper}>
                <section className={classes.bodyContainer}>
                    <Typography variant={'h1'} component={'h1'}>
                        News
                    </Typography>
                    <div className={classes.articleListContainer}>
                        {
                            blogList && renderNewsList(blogList)
                        }
                    </div>
                </section>
            </Container>
        </Layout>
    )
}

export default NewsPage

