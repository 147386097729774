export const dateStringToLongDate = (dateString: string) => {
  return new Date(dateString).toLocaleDateString('en-AU', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    weekday: 'long'
  })
}

export const dateStringToShortDate = (dateString: string) => {
  return new Date(dateString).toLocaleDateString('en-AU', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
}

export const getNodesFromEdges = (edges: any[]): any[] => {
  const listOfNodes: any[] = []
  edges.forEach(edge => listOfNodes.push({...edge.node}))
  return listOfNodes
}
